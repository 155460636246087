@font-face {
  font-family: 'Helvetica Neue';
  src: local('Helvetica Neue'), url('/fonts/HelveticaNeueUltraLight.otf') format('opentype');
  font-weight: 100;
  font-display: block;
}

@font-face {
  font-family: 'Helvetica Neue';
  src:  local('Helvetica Neue'), url('/fonts/HelveticaNeueThin.otf') format('opentype');
  font-weight: 200;
  font-display: block;
}

@font-face {
  font-family: 'Helvetica Neue';
  src:  local('Helvetica Neue'), url('/fonts/HelveticaNeueLight.otf') format('opentype');
  font-weight: 300;
  font-display: block;
}

@font-face {
  font-family: 'Helvetica Neue';
  src:  local('Helvetica Neue'), url('/fonts/HelveticaNeueRoman.otf') format('opentype');
  font-weight: 400;
  font-display: block;
}

@font-face {
  font-family: 'Helvetica Neue';
  src:  local('Helvetica Neue'), url('/fonts/HelveticaNeueMedium.otf') format('opentype');
  font-weight: 500;
  font-display: block;
}

@font-face {
  font-family: 'Helvetica Neue';
  src:  local('Helvetica Neue'), url('/fonts/HelveticaNeueMedium.otf') format('opentype');
  font-weight: 600;
  font-display: block;
}

@font-face {
  font-family: 'Helvetica Neue';
  src:  local('Helvetica Neue'), url('/fonts/HelveticaNeueBold.otf') format('opentype');
  font-weight: 700;
  font-display: block;
}

@font-face {
  font-family: 'Helvetica Neue';
  src:  local('Helvetica Neue'), url('/fonts/HelveticaNeueHeavy.otf') format('opentype');
  font-weight: 800;
  font-display: block;
}

@font-face {
  font-family: 'Helvetica Neue';
  src:  local('Helvetica Neue'), url('/fonts/HelveticaNeueBlack.otf') format('opentype');
  font-weight: 900;
  font-display: block;
}


@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .mask-gradient {
    -webkit-mask-image: -webkit-gradient(linear, left 20%, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
    mask-image: linear-gradient(to bottom, rgba(0,0,0,1) 20%, transparent 100%);
  }



}

@layer base {
  *:focus {
    outline: none;
  }

  a {
    @apply hover:text-primary-hover;
  }

  * {
    -webkit-text-fill-color: currentColor;
  }

  html {
    font-family: "Helvetica Neue";
    font-size: clamp(0.68rem, 0.7vw + 0.25rem, 0.75rem);
    text-rendering: optimizeLegibility;
    @apply text-white bg-black;
  }

  h1 {
    font-size: 5rem;
    line-height: 6rem;
  }

  h1 {
    @apply font-medium;
  }

  h2 {
    font-size: 2.5rem;
    line-height: 3rem;
  }

  * {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0.1);
  }

  [data-fi-ic] {
    width: 2rem;
    height: 2rem;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: auto;
  }

  th {
    @apply font-normal text-gray-400 text-left;
  }

  td {
    @apply border-t border-gray-700;
  }

  th:last-child,
  td:last-child {
    @apply text-right;
  }

  th:not(:first-child):not(:last-child),
  td:not(:first-child):not(:last-child) {
    @apply px-2;
  }

  td,
  th {
    @apply py-6;
  }
}
@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .topleft-gradient {
    background: linear-gradient(
      120deg,
      rgba(31, 31, 31, 0.91) 2.6%,
      rgba(50, 50, 50, 0) 30.59%
    );
  }
}



/* VideoJS Fitters Theme */
.vjs-fitters.video-js {
  color: #ffffff;
}
.vjs-fitters div.vjs-control-bar{
  background-color: #fff;
  /* blurry glass-like background */
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(5px);
}
.vjs-fitters div.vjs-progress-control div.vjs-progress-holder{
  margin: 0;
  height: 1px;
  background-color: #fff;
}
.vjs-fitters div.vjs-progress-control:hover div.vjs-progress-holder{
  height: 3px;
}
.vjs-fitters div.vjs-progress-control div.vjs-load-progress{
  background-color: #fff;
  opacity: 1;
}
.vjs-fitters div.vjs-progress-control div.vjs-play-progress{
  background-color: #fff;
  box-shadow: 0 0 6px #fff;
  -webkit-box-shadow: 0 0 6px #fff;
  -moz-box-shadow: 0 0 6px #fff;
}
.vjs-fitters div.vjs-progress-control{
  position: absolute;
  top: -50%;
  width: 100%;
}
.vjs-fitters div.vjs-control-bar .vjs-volume-panel{
  margin-left: auto;
}
.vjs-fitters div.vjs-volume-horizontal.vjs-volume-control.vjs-control .vjs-volume-bar{
  height: 2px;
}
.vjs-fitters div.vjs-volume-horizontal.vjs-volume-control.vjs-control .vjs-volume-level{
  height: 2px;
  box-shadow: 0 0 6px #ffffff66;
  -webkit-box-shadow: 0 0 6px #ffffff66;
  -webkit-appearance: none;
  -moz-box-shadow: 0 0 6px #ffffff66;
}
.vjs-fitters div.vjs-volume-horizontal.vjs-volume-control.vjs-control .vjs-volume-level::before{
  line-height: 2px;
  margin-top: 1px;
}

.vjs-fitters span.vjs-icon-placeholder{
  text-shadow: 0 0 3px #fff;
  transition: text-shadow 0.2s ease-in-out;
}

.vjs-fitters span.vjs-icon-placeholder:hover{
  text-shadow: 0 0 6px #fff;
  transition: text-shadow 0.2s ease-in-out;
}

.vjs-fitters .vjs-subs-caps-button {
  display: none;
}

.vjs-fitters .vjs-big-play-button {
  color: #fff;
  background: none !important;
  border:none !important;
  transform: scale(1.5);
}


.landing-shadow {
  -webkit-box-shadow: inset 0px 0px 20rem 2px #000000;
  box-shadow: inset 0px 0px 20rem 2px #000000;
  }


/* CHARTS */
.legend-container {
  margin-top: 10px;
  position: absolute;
}

.chart-legend {
  position: relative;
  margin-top: 30px;
  margin-left: 40px;
  height: 70px;
  font-family: Arial;
  font-size: 12px;
  color: #fff;
  background: #404040;
  display: flex;
  flex-direction: column;
  padding: 12px 5px;
  border-radius: 2px;
  opacity: 0.8;
}

.chart-legend p {
  margin: 0;
  color: #fff;
}

.chart-legend p::before {
  content: "";
  display: inline-block;
  width: 4px;
}

.chart-legend .line {
  margin-right: 3px;
  margin-left: 4px;
  border-radius: 2px;
  clear: both;
  line-height: 140%;
  padding-right: 15px;
  display: flex;
  align-items: center;
}

.chart-legend .swatch {
  display: inline-block;
  width: 8px;
  height: 8px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.chart-legend .line .swatch {
  display: inline-block;
  margin-right: 3px;
  border-radius: 2px;
}

/* NODE NETWORK */
.node-container {
  position: relative;
  width: 100%;
}
